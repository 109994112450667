<div class="nav-items">
    <div class="nav-item">
        <fa-icon [icon]="faDashboard"></fa-icon>
        <span>Dashboard</span>
    </div>
    <div class="nav-item">
        <fa-icon [icon]="faLocation"></fa-icon>
        <span>Locations</span>
    </div>

    <div class="nav-item">
        <fa-icon [icon]="faBox"></fa-icon>
        <span>Products</span>
    </div>
    <div class="nav-item">
        <fa-icon [icon]="faMoneyBill"></fa-icon>
        <span>Sales</span>
    </div>
    <div class="nav-item">
        <fa-icon [icon]="faChartBar"></fa-icon>
        <span>Statistics</span>
    </div>


</div>
