<div class="top-section">
    <app-top-widgets></app-top-widgets>
</div>
<div class="middle-section">
    <div class="widget left">
        <app-sales-by-month></app-sales-by-month>
    </div>
    <div class="widget right">
        <app-sales-by-category></app-sales-by-category>
    </div>
</div>
<div class="bottom-section">
    <div class="widget left">
        <app-last-transactions></app-last-transactions>
    </div>
    <div class="widget right">
        <app-top-products></app-top-products>
    </div>
</div>
