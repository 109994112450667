<div class="widget-container">
    <div class="widget yellow">
        <div class="icon">
            <fa-icon [icon]="faLocation"></fa-icon>
        </div>
        <div class="value">
            <div class="top">32</div>
            <div class="bottom">Locations</div>
        </div>
    </div>
    <div class="widget yellow">
        <div class="icon">
            <fa-icon [icon]="faShop"></fa-icon>
        </div>
        <div class="value">
            <div class="top">295</div>
            <div class="bottom">Total Orders</div>
        </div>
    </div>
    <div class="widget yellow">
        <div class="icon">
            <fa-icon [icon]="faBoxes"></fa-icon>
        </div>
        <div class="value">
            <div class="top">150</div>
            <div class="bottom">Deliverd Orders</div>
        </div>
    </div>
    <div class="widget yellow">
        <div class="icon">
            <fa-icon [icon]="faMoneyBill"></fa-icon>
        </div>
        <div class="value">
            <div class="top">173</div>
            <div class="bottom">Total Customers</div>
        </div>
    </div>
</div>
