<section class="page-title-area">
    <div class="container">
        <div class="header">
            <app-header></app-header>
        </div>
        <div class="main-container">
            <div class="side=nav">
                <app-side-nav></app-side-nav>
            </div>
            <div class="main">
                <app-main></app-main>
            </div>
        </div>
    </div>
</section>

