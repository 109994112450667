import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageComponent } from '../../pages/error-page/error-page.component';
import { HeaderComponent } from './header/header.component';
import {SideNavComponent} from './side-nav/side-nav.component';
import{MainComponent} from './main/main.component';


@Component({
  selector: 'app-zendataplatform',
  templateUrl: './zendataplatform.component.html',
  styleUrl: './zendataplatform.component.scss',
   standalone: true, // If ZendataplatformComponent is also standalone
   imports: [HeaderComponent,SideNavComponent,MainComponent] // Import HeaderComponent here!
})
export class ZendataplatformComponent implements OnInit {



 constructor(private router:Router) { }

    ngOnInit(): void {
    }





}
