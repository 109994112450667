import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Solutions',
            title: 'We Provide Different Technological Solutions',
            paragraphText: 'Unlock the full potential of your business with our software development and AI solutions.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Data analytics & AI',
            paragraphText: 'Accelerate Growth with Data Analytics & AI at the Core.',
            link: '',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Software Services',
            paragraphText: ' Technology is our passion. We create our technological products to provide you with the best software solutions.',
            link: '',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'E-Commerce Platforms & Mobile Apps',
            paragraphText: 'Mobile apps and e-commerce platforms are at the forefront of reshaping the customer experience, playing a pivotal role in defining the future of commerce in our digitally-driven world',
            link: '',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}
