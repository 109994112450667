import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDashboard, faLocationDot, faShop, faBox, faMoneyBill, faChartBar  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent  {

  faDashboard = faDashboard;
  faLocation = faLocationDot;
  faBox = faBox;
  faMoneyBill = faMoneyBill;
  faChartBar = faChartBar;


  constructor() { }




}
