import { Component } from '@angular/core';
import { TopWidgetsComponent } from './top-widgets/top-widgets.component';
import { SalesByMonthComponent } from './sales-by-month/sales-by-month.component';
import {TopProductsComponent} from './top-products/top-products.component';
import {LastTransactionsComponent} from './last-transactions/last-transactions.component';
import {SalesByCategoryComponent} from './sales-by-category/sales-by-category.component'
@Component({
  selector: 'app-main',
  standalone: true,
  imports: [TopWidgetsComponent,SalesByMonthComponent,TopProductsComponent,LastTransactionsComponent,SalesByCategoryComponent], // Import TopWidgetsComponent
  templateUrl: './main.component.html', // Link to the HTML template
  styleUrl: './main.component.scss', // Link to the SCSS file
})
export class MainComponent {}
